@import url(https://fonts.googleapis.com/css2?family=Montserrat&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
  background-color: #fbf9fa !important;
}

.loading {
  min-width: 100%;
  min-height: 100vh;
  background-color: #ffffff;
}

.App {
  min-width: 100%;
}

.navbar .navbar-brand {
  text-decoration: none;
  color: rgb(51, 51, 51);
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  padding: 30px 35px;
}

.navbar .navbar-nav a {
  margin: 0px 25px;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  color: #878787;
  text-decoration: none;
}

.navbar .navbar-nav .active {
  color: #079b92 !important;
}

.carousel {
  height: auto;
}

.carousel-indicators button {
  background-color: #046f68 !important;
  width: 50px !important;
  height: 10px !important;
}

.carousel .carousel-caption {
  text-align: left;
  /* left: 10%; */
  padding-top: calc(10%);
  height: 100%;
  overflow-y: auto;
}

.carousel .carousel-item {
  height: 100%;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  align-self: center;
}

.carousel .carousel-caption p {
  max-width: 35%;
  margin-top: 15px;
  margin-bottom: 30px;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 30px;

  color: #898ea8 !important;
}

.carousel .carousel-item img {
  height: 100%;
}

.carousel .carousel-caption h3 {
  font-size: 50px;
  font-weight: 700;
  font-family: 'Poppins', sans-serif;
  line-height: 115% !important;
  letter-spacing: 0.45rem;
  max-width: 500px;
  color: #555974 !important;
  text-transform: uppercase;
}

.carousel .btn-tour {
  padding: 20px 70px !important;
  background-color: #00988f;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.1rem;
  border: none;
  text-decoration: none;
}

.carousel .btn-disabled {
  padding: 20px 70px !important;
  background-color: #929292;
  color: white;
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.1rem;
  border: none;
  text-decoration: none;
}

.carousel .btn-disabled:hover {
  background-color: #787878;
  text-decoration: none;
}

.carousel .btn-tour:hover {
  background-color: #007971 !important;
  text-decoration: none;
}

.carousel .btn-next {
  background-color: #00988f;
  border: none;
}

.carousel .carousel-control-next-icon {
  color: #007971 !important;
}

.carousel .carousel-control-prev,
.carousel-control-next {
  width: 10% !important;
}

.modal .modal-90w {
  width: 96%;
  max-width: none !important;
}

.modal-title {
  font-family: 'Poppins', sans-serif;
}

.animate__animated.animate__fadeInDown {
  --animate-duration: 1.2s;
}

@media only screen and (min-width: 900px) {
  .animate-md-stop {
    /*CSS transitions*/
    transition-property: none !important;
    /*CSS transforms*/
    -webkit-transform: none !important;
    transform: none !important;
    /*CSS animations*/
    -webkit-animation: none !important;
    animation: none !important;
  }
}

@media only screen and (max-width: 600px) {
  .navbar {
    background-color: #089b92;
    padding: 0 !important;
  }

  .navbar .navbar-brand {
    padding: 17px 20px !important;
  }

  .navbar .navbar-brand img {
    width: 80px;
  }

  .navbar .navbar-nav a {
    color: white;
    font-size: 12px;
    padding-left: 0px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  .navbar .navbar-nav {
    padding-bottom: 10px;
  }

  .carousel {
    min-height: 100%;
    padding-bottom: 100px;
  }

  .carousel-inner {
    min-height: 100vh;
  }

  .carousel .carousel-item {
    min-height: 100%;
  }

  .carousel .carousel-caption {
    bottom: auto;
    height: auto;
    top: auto;
    margin-top: -90px;
    overflow: hidden;
    /* overflow-y: auto; */
    /* padding-bottom: 100px; */
  }

  .carousel .carousel-caption h3 {
    font-size: 20px;
    letter-spacing: 0.13rem;
    line-height: 125% !important;
  }

  .carousel .carousel-caption p {
    font-size: 11px;
    max-width: 100%;
    line-height: 20px;
    margin-top: 15px;
  }

  .carousel-control-next {
    display: none !important;
  }

  .carousel-control-prev {
    display: none !important;
  }

  .carousel .btn-tour {
    padding: 10px 30px !important;
    font-size: 14px;
    letter-spacing: 0rem;
    border: none;
  }

  .carousel .carousel-inner img {
    margin-top: 4vh;
  }

  .carousel .btn-tour:hover {
    background-color: #007971 !important;
  }

  .carousel .btn-tour:active {
    background-color: #007971 !important;
  }

  .carousel .btn-next:hover {
    background-color: #007b73 !important;
  }

  .carousel .btn-next:focus {
    background-color: #007b73;
  }

  .modal-body {
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .animate__animated.animate__bounceInDown {
    --animate-duration: 1.2s;
  }

  .panorama .btn-home {
    left: 35% !important;
    font-size: 15px !important;
  }

  .panorama .btn-home span {
    font-size: 10px;
  }
}

/* Panorama */
.panorama {
  height: 100vh;
  padding: 0 !important;
  margin: 0 !important;
}

.panorama .btn-home {
  position: absolute;
  text-align: center;
  left: 46%;
  margin-top: 5px;
  font-size: 19px;
  background: none;
  border: 0px;
  font-family: 'Poppins', sans-serif;
}

.panorama .btn-home:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
  background: none !important;
  border: 0px !important;
}

.panorama .icon {
  margin: auto;
}

.panorama .btn-home a {
  text-decoration: none;
  color: white;
  margin: auto;
}

.panorama span {
  margin-top: 5px;
  font-size: 16px;
  color: white;
  text-decoration: none !important;
}

/* About Page */

.about {
  height: 100%;
  width: 100%;
  justify-content: center;
  padding-top: 120px;
  margin-bottom: 200px;
}

.about .img-about {
  justify-content: center;
  align-items: center;
  width: 80%;
}

.about-desc {
  left: 13% !important;
  position: absolute;
}

.about h1 {
  margin-top: 30px;
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 30px;
  color: #171717;
}

.about .text-desc {
  width: 80%;
}

.teams {
  justify-content: center;
  margin-top: 50px;
  padding: 0px 13% !important;
  margin-bottom: 50px;
}

.card {
  background: none !important;
  border: 0px !important;
  transition: 0.2s all ease-in-out;
}

@-webkit-keyframes rotBGimg {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes rotBGimg {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.card-about {
  position: relative;
  border: none;
  transition: 0.4s all ease-in-out;
  /* height: 22rem; */
}

.card-about .card-img {
  -webkit-filter: grayscale(80%);
          filter: grayscale(80%);
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  transition: 0.4s all ease-in-out;
}

.teams .card-text {
  opacity: 0;
  color: white;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  grid-gap: 15px;
  gap: 15px;
  bottom: 10%;
  align-self: center;
  font-weight: 800;
  font-size: 0.8vw;
  font-family: sans-serif;
  transition: 0.4s all ease-in-out;
  z-index: 2;
}

@media only screen and (max-width: 600px) {
  .teams .card-text {
    font-size: 1vh;
  }

  .card-about {
    padding: 0px 4px !important;
  }

  .about {
    margin-bottom: 160px;
  }

  .card-about .card-img {
    -webkit-filter: grayscale(0%);
            filter: grayscale(0%);
  }
}

.card-about:hover {
  -webkit-transform: scale(1.04);
          transform: scale(1.04);
  transition: 0.4s all ease-in-out;
}

.card-about:hover .card-img {
  /* height: 65%; */
  -webkit-filter: blur(7px);
          filter: blur(7px);
  -webkit-filter: grayscale(0%);
          filter: grayscale(0%);
  -webkit-animation: anim 3s infinite;
          animation: anim 3s infinite;
  transition: 0.4s all ease-in-out;
}

.card-about:hover .card-text {
  opacity: 1;
  -webkit-animation: animUp 3s infinite;
          animation: animUp 3s infinite;
  /* animation: anim 3s infinite; */
  transition: 0.4s all ease-in-out;
}

@-webkit-keyframes anim {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }

  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes anim {
  0% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }

  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }

  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@-webkit-keyframes animUp {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }

  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

@keyframes animUp {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }

  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}

.card-about .card {
  /* position: absolute; */
}

.card-about .card img {
  /* width: 200px; */
}

.card-about .leftBtn {
  text-align: center;
  align-items: center;
  align-self: center;
  align-content: center;
}

/* ========================================== */

*:before,
*:after {
  box-sizing: inherit;
}

.carousel__wrap {
  margin-top: 10%;
  align-items: center;
  display: flex;
  flex: 1 1;
  justify-content: center;
  position: relative;
  width: 80%;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.carousel__inner {
  height: 40rem;
  position: relative;
  width: calc(90rem);
}

.carousel__container {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.carousel__slide-list {
  height: 100%;
  left: 50%;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: calc(330rem);
}

.carousel__slide-item {
  display: inline-block;
  height: 30rem;
  margin: 0;
  padding: 1rem;
  position: absolute;
  transition: all 0.3s;
  width: 30rem;
}

.carousel__slide-item-img-link {
  cursor: zoom-in;
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.carousel__slide-item-img-link img {
  height: 100%;
  object-fit: cover;
  transition: all 0.5s ease;
  width: 100%;
}
.carousel__slide-item-img-link::after {
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  content: 'read more';
  display: flex;
  height: 100%;
  justify-content: center;
  opacity: 0;
  position: absolute;
  transition: all 0.5s ease;
  width: 100%;
}
.carousel__slide-item-img-link:hover::after {
  opacity: 1;
}
.carousel__slide-item-img-link:hover img {
  -webkit-transform: scale(1.3);
          transform: scale(1.3);
}

.carousel-slide-item__body {
  bottom: -2.5rem;
  height: 10%;
  position: absolute;
}
.carousel-slide-item__body h4 {
  margin: 0.7rem 0 0;
  text-transform: uppercase;
}
.carousel-slide-item__body p {
  font-size: 1.2rem;
  line-height: 1.3;
  margin: 0.7rem 0 0;
}

.carousel__btn {
  align-items: center;
  background: 0;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.carousel__btn--prev {
  left: -10rem;
}
.carousel__btn--next {
  right: -10rem;
}

.carousel__btn-arrow {
  border: solid black;
  border-width: 0 0.4rem 0.4rem 0;
  height: 6rem;
  padding: 3px;
  width: 6rem;
  z-index: 10;
}
.carousel__btn-arrow--left {
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
}
.carousel__btn-arrow--right {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.carousel__dots {
  display: inline-block;
  left: 50%;
  margin-top: 2rem;
  position: absolute;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.carousel__dots .dot {
  background: #ccc;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  height: 2rem;
  margin: 0 0.3rem;
  outline: none;
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
  width: 2rem;
}
.carousel__dots .dot.active {
  background: black;
}
/* ========================================== */

@media only screen and (max-width: 600px) {
  .about {
    padding-top: 80px;
  }
  .about img {
    width: 100%;
  }

  .about h1 {
    font-size: 16px;
    font-weight: 700;
  }

  .about .text-desc {
    font-size: 11px;
    color: #7c7c7c;
  }
}

/* Category */

.category {
  padding-top: 180px;
  padding-bottom: 100px;
}

.category h5 {
  font-size: 23px;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
}

.category .card-link {
  text-decoration: none;
}

.category .card {
  padding: 20px 15px;
  border-radius: 14px;
  transition: all ease-in-out 0.5s;

  background: #fefefe;
}

.category .card .card-title {
  font-family: 'Poppins', sans-serif;
  font-weight: bold;
  font-size: 20px;
  color: #171717;
}

.category .card .card-text {
  color: #b7b7b7;
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}

.category .card .card-img-top {
  width: 40% !important;
  justify-content: center;
  align-content: center;
  align-self: center;
}

.category .card:hover {
  box-shadow: 6px 6px 20px 10px rgba(171, 171, 171, 0.25);
  transition: all ease-in-out 0.5s;
  background: #ffffff;
}

.categoryDetail {
  padding-top: 180px;
  padding-bottom: 100px;
}

.categoryDetail h5 {
  font-size: 23px;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
}

.categoryDetail .card {
  transition: all ease-in-out 0.5s;
}

.categoryDetail .card-body {
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
}

.categoryDetail .card-link {
  text-decoration: none;
}

.categoryDetail .card .card-title {
  color: #171717;
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  font-weight: bold;
}

.categoryDetail .card .card-text {
  color: #b7b7b7;
  font-family: 'Poppins', sans-serif;
  font-size: 11px;
  height: 120px;
  word-wrap: break-word;
  overflow-y: hidden;
}

.categoryDetail .card .btn-tour {
  background: #007971;
  color: #ffffff;
  font-size: 12px;
  font-family: 'Popppins', sans-serif;
  font-weight: 500;
  padding: 8px 30px;
}

.categoryDetail .card .btn-soon {
  background: #838383;
  color: #ffffff;
  font-size: 12px;
  font-family: 'Popppins', sans-serif;
  font-weight: 500;
  padding: 8px 30px;
  border: 0px;
}

.categoryDetail .card .btn-soon:hover {
  background: #747474;
  color: #ffffff;
  box-shadow: 6px 6px 10px rgba(171, 171, 171, 0.25);
}

.categoryDetail .card .btn-tour:hover {
  background: #007971;
  color: #ffffff;
  box-shadow: 6px 6px 10px rgba(171, 171, 171, 0.25);
}

.categoryDetail .card:hover {
  box-shadow: 6px 6px 20px 10px rgba(171, 171, 171, 0.25);
  transition: all ease-in-out 0.5s;
}

@media only screen and (max-width: 600px) {
  .category {
    padding: 100px 30px !important;
  }

  .category h5 {
    font-size: 18px;
  }

  .category .card {
    padding: 15px 2px;
    border-radius: 8px;
  }

  .category .card .card-title {
    font-size: 12px;
  }

  .category .card .card-text {
    font-size: 9px;
    text-align: left;
  }

  .categoryDetail {
    padding: 100px 30px !important;
  }

  .categoryDetail h5 {
    font-size: 18px;
  }

  .categoryDetail .card {
    padding-bottom: 20px;
    border-radius: 8px;
  }

  .categoryDetail .card .card-title {
    font-size: 16px;
  }

  .categoryDetail .card .card-text {
    font-size: 10px;
    height: 75px;
  }

  .categoryDetail .card .card-body {
    padding: 0px 20px;
  }

  .categoryDetail .card button {
    font-size: 9px !important;
    justify-content: center;
    align-self: center;
    text-align: center;
    width: 100%;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
  }
}

